import React from 'react'
import styled from 'styled-components'

import SvgCheck from '../../svg/check'

interface IProps {
  onChange: () => void
  label?: string
  checked: boolean
  variant: 'normal' | 'contained'
  width: string
  labelFor?: any
}

export default function Checkbox({ onChange, label, checked, variant, width, labelFor }: IProps) {
  return (
    <Container checked={checked} onClick={onChange} variant={variant} width={width}>
      <input checked={checked} onChange={onChange} type="checkbox" />
      {checked && (
        <div>
          <div>
            <SvgCheck />
          </div>
        </div>
      )}
      {label && <p>{label}</p>}
      {labelFor && labelFor}
    </Container>
  )
}

const Container = styled.div<{ checked: boolean; variant: 'normal' | 'contained'; width: string }>`
  align-items: ${({ variant }) => (variant === 'contained' ? 'center' : 'flex-start')};
  background-color: ${({ checked, variant, theme }) =>
    checked && variant === 'contained' ? theme.palette.primary.main : 'transparent'};
  border-radius: 4px;
  box-shadow: ${({ checked, variant }) =>
    checked && variant === 'contained' ? '0 6px 24px 0 rgba(0, 29, 47, 0.5)' : 'none'};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: flex-start;
  padding: ${({ variant }) => (variant === 'contained' ? '0 16px' : 0)};
  position: relative;
  width: ${({ width }) => width};

  & > input {
    border: ${({ theme }) => `solid 1px ${theme.palette.text.primary}`};
    cursor: pointer;
    height: 24px;
    margin: 0;
    opacity: ${({ checked }) => (checked ? 0 : 1)};
    width: 24px;
  }

  & > div {
    align-items: ${({ variant }) => (variant === 'contained' ? 'center' : 'flex-start')};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: ${({ variant }) => (variant === 'contained' ? 'center' : 'flex-start')};
    left: ${({ variant }) => (variant === 'contained' ? '16px' : '0')};
    opacity: ${({ checked }) => (checked ? 1 : 0)};
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 24px;

    & > div {
      align-items: center;
      background-color: ${({ theme }) => theme.palette.text.secondary};
      border: ${({ theme }) => `solid 1px ${theme.palette.text.primary}`};
      border-radius: 2px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 24px;
      width: 100%;

      & > svg {
        height: 20px;
        width: 20px;

        & > path {
          fill: ${({ theme }) => theme.palette.primary.main};
        }
      }
    }
    @media screen and (max-width: 480px) {
      left: 8px;
    }
  }

  & > p {
    color: ${({ checked, variant, theme }) =>
      checked && variant === 'contained' ? theme.palette.text.secondary : theme.palette.text.primary};
    font-size: 16px;
    font-family: ${({ variant }) =>
      variant === 'contained' ? "'Proxima Nova Extrabold', sans-serif" : "'Proxima Nova Regular', sans-serif"};
    margin: ${({ variant }) => (variant === 'contained' ? '0 0 0 32px' : '-4px 0 0 16px')};
    width: calc(100% - 24px);

    @media screen and (max-width: 480px) {
      margin: ${({ variant }) => (variant === 'contained' ? '0 0 0 8px' : '-4px 0 0 8px')};
    }
    & > a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 480px) {
    height: auto;
    padding: ${({ variant }) => (variant === 'contained' ? '8px' : '0')};
  }
`

import React from 'react'
import styled from 'styled-components'

import Button from '../../buttons/main'

interface IProps {
  submit: () => void
}

export default function PlatformAccess({ submit }: IProps) {
  return (
    <Container>
      <h6>Merci !</h6>
      <p>Votre demande a bien été prise en compte. Un accès va bientôt vous être attribué.</p>
      <p>Dites-nous en davantage sur vous et vos projets.</p>
      <ButtonsContainer>
        <Button color="primary" onClick={submit} variant="contained" width="168px">
          Suivant
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h6 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 24px;
    font-family: 'Proxima Nova Extrabold', sans-serif;
    margin: 0 0 16px 0;
  }

  & > p {
    font-size: 16px;
    max-width: 584px;
  }

  & > p:nth-last-child(1) {
    margin: 0 0 16px 0;
  }

  & > button {
    margin-top: 48px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    :hover {
      opacity: 0.7;
    }
  }
`

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
  width: 100%;

  & > button {
    :hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 480px) {
    margin-top: 0;

    & > button {
      width: 100%;
    }
  }
`

import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { getCookie } from '../../../utils/cookies'
import Button from '../../buttons/main'

export default function Confirmation() {
  return (
    <Container id={getCookie('enableCookieTagManager') === 'true' ? 'contact-lms-confirmation' : undefined}>
      <h6>Merci !</h6>
      <p>Votre demande a bien été prise en compte. Un de nos experts revient vers vous dans les plus brefs délais.</p>
      <Link to="/">
        <Button color="primary" variant="outlined" width="168px">
          {"Retour à l'accueil"}
        </Button>
      </Link>
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h6 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 24px;
    font-family: 'Proxima Nova Extrabold', sans-serif;
    margin: 0 0 16px 0;
  }

  & > p {
    font-size: 16px;
    margin: 0 0 16px 0;
    max-width: 584px;
  }

  & > button {
    margin-top: 48px;

    @media screen and (max-width: 480px) {
      width: 100%;
    }

    :hover {
      opacity: 0.7;
    }
  }
`

import React from 'react'
import styled from 'styled-components'

interface IProps {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  placeholder: string
  value: string
}

export default function TextArea({ onChange, placeholder, value }: IProps) {
  return <Container onChange={onChange} placeholder={placeholder} value={value} />
}

const Container = styled.textarea`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.info.main};
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-family: 'Proxima Nova Regular', sans-serif;
  font-size: 16px;
  height: 120px;
  justify-content: center;
  padding: 16px;
  resize: none;
  width: 100%;
`

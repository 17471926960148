import { ContactGoal, UserType } from '@mysg-website/api-client/lib'
import React from 'react'
import styled from 'styled-components'

import { TCustomerTypes, TGoals, TSelectedGoals } from '../../../hooks/forms/contact/project'
import { IFormError } from '../../../types/custom'
import Button from '../../buttons/main'
import Checkbox from '../fields/checkbox'
import FileInput from '../fields/file'
import TextArea from '../fields/text-area'

interface IProps {
  attachment: File | null
  customerType: UserType
  customerTypes: TCustomerTypes
  error: IFormError
  goals: TGoals
  goBack: () => void
  message: string
  newsletterSubscribed: boolean
  selectedGoals: TSelectedGoals
  setAttachment: (value: File | null) => void
  setCustomerType: (value: UserType) => void
  setMessage: (value: string) => void
  setSelectedGoals: (value: TSelectedGoals) => void
  submit: () => void
  subscribeNewsletter: (value: boolean) => void
  loading: boolean
}

export default function Project({
  customerTypes,
  goals,
  customerType,
  selectedGoals,
  message,
  attachment,
  error,
  setCustomerType,
  setSelectedGoals,
  setMessage,
  setAttachment,
  submit,
  goBack,
  loading,
}: IProps) {
  function handleCheck(key: ContactGoal) {
    setSelectedGoals({ ...selectedGoals, [key]: !selectedGoals[key] })
  }

  function handleChangeAttachment(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event?.target?.files

    setAttachment((files && files[0]) || null)
  }

  return (
    <Container>
      <h6>Qui êtes-vous ? *</h6>
      <TypeContainer>
        {customerTypes.map(({ key, title }) => (
          <TypeButton key={key} onClick={() => setCustomerType(key)} selected={key === customerType}>
            {title}
          </TypeButton>
        ))}
      </TypeContainer>
      <h6>Pourquoi nous contacter ? *</h6>
      <GoalsContainer>
        {goals &&
          goals.map(({ key, title }) => (
            <Checkbox
              checked={selectedGoals[key] || false}
              key={key}
              label={title}
              onChange={() => handleCheck(key)}
              variant="contained"
              width="100%"
            />
          ))}
        <ErrorMessage>{error.fieldName === 'goals' ? error.value : ''}</ErrorMessage>
      </GoalsContainer>
      <h6>Un message pour nous en dire plus ?</h6>
      <TextArea
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value)}
        placeholder="J’aimerais échanger à propos de ..."
        value={message}
      />
      <FileInput
        label="+ Pièce jointe"
        onChange={handleChangeAttachment}
        remove={() => setAttachment(null)}
        value={attachment}
      />
      <ErrorMessage>{error.fieldName === 'attachment' ? error.value : ''}</ErrorMessage>
      <StyledP>
        En validant ce formulaire vous acceptez notre{' '}
        <a href="/mentions-legales" target="_blank">
          politique d&apos;utilisation des données.
        </a>
      </StyledP>
      <ButtonsContainer>
        <Button color="primary" onClick={goBack} variant="outlined" width="168px">
          Précédent
        </Button>
        <Button color="primary" disabled={loading} loading={loading} onClick={submit} variant="contained" width="168px">
          Suivant
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 16px;
  width: 100%;

  & > h6 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 24px;
    font-family: 'Proxima Nova Extrabold', sans-serif;
    margin: 0 0 16px 0;
  }
`

const TypeContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 64px;
  width: 100%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;
  }
`

const StyledP = styled.p`
  font-size: 16px;
  font-style: 'italic';
  margin: 0 0 12px 0;
  text-align: left;
  width: 100%;

  & > a {
    text-decoration: underline;
    font-weight: bold;
  }
`

const TypeButton = styled.div<{ selected: boolean }>`
  align-items: center;
  background-color: ${({ selected, theme }) => (selected ? theme.palette.primary.main : 'transparent')};
  box-shadow: ${({ selected }) => (selected ? '0 6px 24px 0 rgba(0, 29, 47, 0.5)' : 'none')};
  border-radius: 4px;
  color: ${({ selected, theme }) => (selected ? theme.palette.text.secondary : theme.palette.text.primary)};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-family: 'Proxima Nova Extrabold', sans-serif;
  height: 64px;
  justify-content: center;
  width: calc(25% - 12px);

  :hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0 6px 24px 0 rgba(0, 29, 47, 0.5);
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  @media screen and (max-width: 480px) {
    height: 40px;
    margin: 8px 0;
    width: 100%;
  }
`

const GoalsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > div {
    margin: 16px 0;
  }
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.error.main};
  font-family: 'Proxima Nova Extrabold', sans-serif;
  height: 20px;
  margin: 8px 0 16px 0;
`

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 48px;
  width: 100%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }

  & > button {
    margin: 0 8px;

    @media screen and (max-width: 480px) {
      margin: 4px 0;
      width: 100%;
    }

    :hover {
      opacity: 0.7;
    }
  }
`

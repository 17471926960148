import { ContactGoal, UserType } from '@mysg-website/api-client/lib'
import { useState } from 'react'

import { IFormError } from '../../../types/custom'

export type TCustomerTypes = Array<{ key: UserType; title: string }>

export type TGoals = Array<{ key: ContactGoal; title: string }>

export type TSelectedGoals = { [key in ContactGoal]?: boolean }

interface IState {
  attachment: File | null
  customerType: UserType
  error: IFormError
  message: string
  newsletterSubscribed: boolean
  selectedGoals: TSelectedGoals
}

interface IActions {
  setAttachment: (value: File | null) => void
  setCustomerType: (value: UserType) => void
  setMessage: (value: string) => void
  setSelectedGoals: (value: TSelectedGoals) => void
  submit: () => void
  subscribeNewsletter: (value: boolean) => void
}

export function useProject(
  customerTypes: TCustomerTypes,
  goalNames: TGoals,
  goNext: () => void
): { state: IState; actions: IActions } {
  const [customerType, setCustomerType] = useState(customerTypes[0].key)
  const [selectedGoals, setSelectedGoals] = useState<TSelectedGoals>({})
  const [message, setMessage] = useState('')
  const [attachment, setAttachment] = useState<File | null>(null)
  const [newsletterSubscribed, subscribeNewsletter] = useState(true)
  const [error, setError] = useState<IFormError>({ fieldName: null, value: '' })

  function submit() {
    let errorFieldName = null
    let errorValue = ''

    if (!Object.values(selectedGoals).find((selected) => selected)) {
      errorFieldName = 'goals'
      errorValue = 'Veuillez selectionner au moins une proposition.'
    } else if (attachment && attachment.size > 10000000) {
      errorFieldName = 'attachment'
      errorValue = 'Votre fichier ne doit pas dépasser 10 Mo.'
    }

    setError({ fieldName: errorFieldName, value: errorValue })

    if (!errorFieldName) {
      goNext()
    }
  }

  return {
    state: {
      customerType,
      selectedGoals,
      message,
      attachment,
      newsletterSubscribed,
      error,
    },
    actions: {
      setCustomerType,
      setSelectedGoals,
      setMessage,
      setAttachment,
      subscribeNewsletter,
      submit,
    },
  }
}

import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import { TemplateContactLmsDataQuery } from '../../../gatsby-types'
import { getTitle } from '../../utils/prismic'
import Breadcrumb from '../../components/common/breadcrumb'
import Layout from '../../layouts/default'
import ContactLmsForm from '../../components/forms/contact-lms'
import { linkGenerator, PrismicDocumentType } from '../../utils/link-generator'
import FooterCards from '../../components/common/slices/footer-cards'
import { transformSeo } from '../../utils/seo-transformer'

import { transformContactLmsNode } from './transformer'

interface IProps {
  data: TemplateContactLmsDataQuery
  path?: string
}

export default function ContactLms({ data: { prismicContactLms }, path }: IProps) {
  const pageContent = transformContactLmsNode(prismicContactLms)
  const seoContent =
    prismicContactLms && prismicContactLms.data && prismicContactLms.data.seo && prismicContactLms.data.seo[0]
      ? transformSeo(prismicContactLms.data.seo[0])
      : undefined

  const { uid, name, title, description, parent, footerCards } = pageContent

  return (
    <Layout path={path} seoContent={seoContent} title={getTitle(name)}>
      <Container>
        <Breadcrumb
          links={[
            { label: parent.name, url: linkGenerator(PrismicDocumentType.HOME) },
            { label: name, url: linkGenerator(PrismicDocumentType.PAGE, uid) },
          ]}
        />
        <h2>{title}</h2>
        <p>{description}</p>
        <ContactLmsForm />
        <FooterCards content={footerCards} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query TemplateContactLmsData {
    prismicContactLms {
      uid
      data {
        parentdocument {
          uid
          document {
            ... on PrismicHome {
              data {
                name {
                  text
                }
              }
            }
          }
        }
        name {
          text
        }
        title {
          text
        }
        description {
          text
        }
        body {
          ... on PrismicContactLmsBodyFooterCards {
            slice_type
            items {
              footercardtitle {
                text
              }
              footercardsubtitle {
                text
              }
              footercardtext {
                text
              }
              footercardlinklabel {
                text
              }
              footercardlinkurl {
                url
              }
              footercardbackground {
                url
              }
            }
          }
        }
        seo {
          ... on PrismicContactLmsSeoMeta {
            id
            primary {
              metatitle {
                text
              }
              metadecription {
                text
              }
              metaogtype {
                text
              }
              metaogtitle {
                text
              }
              metaogdescription {
                text
              }
              metaogimage {
                url
              }
              metaogurl {
                text
              }
              metaogsitename {
                text
              }
              metatwittercard {
                text
              }
              metatwittertitle {
                text
              }
              metatwitterdescription {
                text
              }
              metatwitterimage {
                url
              }
              canonicallink {
                text
              }
            }
          }
        }
      }
    }
  }
`

ContactLms.query = query

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  & > h2 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 52px;
    margin: 16px 0 24px 0;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 34px;
      margin: 24px 0;
    }
  }

  & > p {
    font-size: 16px;
    margin: 0 0 24px 0;
    max-width: 630px;
  }
`

import React from 'react'
import styled from 'styled-components'

interface IProps {
  accept?: string
  label: string
  value: File | null
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  remove: () => void
  english?: boolean
}

export default function FileInput({ accept, label, value, onChange, remove, english }: IProps) {
  const typeRule = accept ? `Format autorisé : ${accept}. ` : ''
  const sizeRule = english ? 'Maximum weight: 10 Mo​' : 'Poids maximum : 10 Mo.'

  return (
    <Container>
      <input accept={accept} key={value?.name} onChange={onChange} type="file"></input>
      {value ? (
        <div>
          <p>{value.name}</p>
          <button onClick={remove}>{english ? 'delete' : 'supprimer'}</button>
        </div>
      ) : (
        <button>{label}</button>
      )}
      <p>{`${typeRule}${sizeRule}`}</p>
    </Container>
  )
}

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  :hover {
    & > button {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  & > input {
    cursor: pointer;
    opacity: 0;

    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }

  & > button {
    color: ${({ theme }) => theme.palette.secondary.main};
    cursor: pointer;
    font-family: 'Proxima Nova Extrabold', sans-serif;
    font-size: 16px;
    height: 24px;
    margin-top: -18px;
    text-decoration: underline;
    z-index: 1;
  }

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 24px;
    justify-content: flex-start;
    margin-top: -18px;
    width: 100%;
    z-index: 1;

    & > p {
      color: ${({ theme }) => theme.palette.secondary.main};
      font-family: 'Proxima Nova Extrabold', sans-serif;
      font-size: 16px;
    }

    & > button {
      cursor: pointer;
      font-size: 12px;
      text-decoration: underline;
      margin-left: 16px;

      :hover {
        opacity: 0.7;
      }
    }
  }

  & > p {
    font-size: 12px;
  }
`

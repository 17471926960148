import React from 'react'

export default function SvgCheck() {
  return (
    <svg height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.394 7.705L9.703 2.4 10.8 3.497 4.393 9.904 1.2 6.71 2.303 5.614z"
        fill="#FFF"
        transform="translate(-510 -828) translate(320 822) translate(184) translate(6 6)"
      />
    </svg>
  )
}

import React from 'react'
import styled from 'styled-components'

interface IProps {
  stepNames: string[]
  currentStep: number
}

export default function ProgressBarMobile({ stepNames, currentStep }: IProps) {
  const filteredStepsName = currentStep === 0 ? stepNames.slice(0, 2) : stepNames

  return (
    <Container>
      <TextContainer>
        <h6>{filteredStepsName[currentStep]}</h6>
        <p>{`${currentStep + 1} / ${filteredStepsName.length}`}</p>
      </TextContainer>
      <ProgressBarContainer currentStep={currentStep} stepsCount={filteredStepsName.length}>
        <div />
      </ProgressBarContainer>
    </Container>
  )
}

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  width: 100%;
`

const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-bottom: 8px;
  width: 100%;

  & > h6 {
    color: ${({ theme }) => theme.palette.secondary.main};
    font-family: 'Proxima Nova ExtraBold', sans-serif;
    font-size: 14px;
    margin: 0;
    text-transform: uppercase;
  }

  & > p {
    font-size: 14px;
    margin: 0;
  }
`

const ProgressBarContainer = styled.div<{ currentStep: number; stepsCount: number }>`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.info.main};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  & > div {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    height: 2px;
    width: ${({ currentStep, stepsCount }) => `${100 * ((currentStep + 1) / stepsCount)}%`};
  }
`

export type Maybe<T> = T;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Upload: any,
};

export enum ContactGoal {
  SUPPORT = 'SUPPORT',
  TALK = 'TALK',
  TOOL = 'TOOL',
  PARTNERSHIP = 'PARTNERSHIP',
  HEALTH_PROJECT = 'HEALTH_PROJECT',
  HEALTH_TALK = 'HEALTH_TALK',
  HEALTH_TRAIN_STAFF = 'HEALTH_TRAIN_STAFF',
  HEALTH_MARKET = 'HEALTH_MARKET',
  HEALTH_SERVICE = 'HEALTH_SERVICE'
}

export enum ContactLang {
  FR = 'FR',
  EN = 'EN'
}

export interface Mutation {
  subscribeNewsletter: Scalars['Boolean'],
  receiveWhitepaper: Scalars['Boolean'],
}


export type MutationsubscribeNewsletterArgs = {
  subscribeInput: NewsletterSubscribeInput
};


export type MutationreceiveWhitepaperArgs = {
  receiveInput: WhitePaperReceiveInput
};

export enum NewsLetterKind {
  CARREER = 'CARREER',
  CONTACT = 'CONTACT',
  CONTACT_LMS = 'CONTACT_LMS',
  CONTACT_EN = 'CONTACT_EN'
}

export interface NewsletterSubscribeInput {
  email: Scalars['String'],
  kind: NewsLetterKind,
  firstname?: Maybe<Scalars['String']>,
  lastname?: Maybe<Scalars['String']>,
  tel?: Maybe<Scalars['String']>,
}

export interface Query {
  isOnline: Scalars['Boolean'],
  requestContact: Scalars['Boolean'],
  requestLmsAccess: Scalars['Boolean'],
  applyJob: Scalars['Boolean'],
}


export type QueryrequestContactArgs = {
  requestContactInput: UserRequestContactInput,
  attachment?: Maybe<Scalars['Upload']>
};


export type QueryrequestLmsAccessArgs = {
  requestLmsAccessInput: UserRequestLmsAccessInput
};


export type QueryapplyJobArgs = {
  applyJobInput: UserApplyJobInput,
  profilePicture?: Maybe<Scalars['Upload']>,
  resume?: Maybe<Scalars['Upload']>,
  portfolio?: Maybe<Scalars['Upload']>
};


export interface UserApplyJobInput {
  jobName?: Maybe<Scalars['String']>,
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  phone: Scalars['String'],
  address?: Maybe<Scalars['String']>,
  postCode?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  website?: Maybe<Scalars['String']>,
  linkedIn?: Maybe<Scalars['String']>,
  twitter?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  googleAds?: Maybe<Scalars['Boolean']>,
}

export interface UserRequestContactInput {
  goals: Array<ContactGoal>,
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  phone: Scalars['String'],
  customerType: UserType,
  message?: Maybe<Scalars['String']>,
  lang: ContactLang,
  googleAds?: Maybe<Scalars['Boolean']>,
}

export interface UserRequestLmsAccessInput {
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  phone: Scalars['String'],
  googleAds?: Maybe<Scalars['Boolean']>,
}

export enum UserType {
  COMPANY = 'COMPANY',
  PUBLIC_ACTOR = 'PUBLIC_ACTOR',
  TRAINING_ORGANIZATION = 'TRAINING_ORGANIZATION',
  INDEPENDENT = 'INDEPENDENT',
  INSTITUTION = 'INSTITUTION',
  FACULTY = 'FACULTY',
  TRAINING_INSTITUTE = 'TRAINING_INSTITUTE',
  INSTITUTION_SOCIETY = 'INSTITUTION_SOCIETY',
  HEALTH_INDUSTRY = 'HEALTH_INDUSTRY',
  HEALTH_TRAINING_ORGANIZATION = 'HEALTH_TRAINING_ORGANIZATION'
}

export interface WhitePaperReceiveInput {
  documentId: Scalars['String'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  phone: Scalars['String'],
  jobTitle: Scalars['String'],
  company: Scalars['String'],
  drawParticipation?: Maybe<Scalars['Boolean']>,
}

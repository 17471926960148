import { UserService } from '@mysg-website/api-client/lib'
import { useState } from 'react'
import validator from 'validator'

import { IFormError } from '../../../types/custom'
import { removePlusFromPhoneNumber } from '../../../utils/string'

interface IState {
  lastName: string
  firstName: string
  email: string
  phone: string
  error: IFormError
}

interface IActions {
  setLastName: (value: string) => void
  setFirstName: (value: string) => void
  setEmail: (value: string) => void
  setPhone: (value: string) => void
  submit: () => void
}

export function useUserInformation(goNext: () => void): { state: IState; actions: IActions } {
  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [error, setError] = useState<IFormError>({ fieldName: null, value: '' })

  async function submit() {
    let errorFieldName = null
    let errorValue = ''

    if (!lastName) {
      errorFieldName = 'lastName'
      errorValue = 'Veuillez saisir votre nom.'
    } else if (!firstName) {
      errorFieldName = 'firstName'
      errorValue = 'Veuillez saisir votre prénom.'
    } else if (!email) {
      errorFieldName = 'email'
      errorValue = 'Veuillez saisir votre e-mail.'
    } else if (!validator.isEmail(email)) {
      errorFieldName = 'email'
      errorValue = "L'e-mail saisi n'est pas valide."
    } else if (!phone) {
      errorFieldName = 'phone'
      errorValue = 'Veuillez saisir votre numéro de téléphone.'
    } else if (!validator.isMobilePhone(removePlusFromPhoneNumber(phone), 'any')) {
      errorFieldName = 'phone'
      errorValue = "Le numéro de téléphone saisi n'est pas valide."
    }

    setError({ fieldName: errorFieldName, value: errorValue })

    if (!errorFieldName) {
      await UserService.requestLmsAccess({ lastName, firstName, email, phone })
      goNext()
    }
  }

  return {
    state: {
      lastName,
      firstName,
      email,
      phone,
      error,
    },
    actions: {
      setLastName,
      setFirstName,
      setEmail,
      setPhone,
      submit,
    },
  }
}
